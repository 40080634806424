define("boondmanager/helpers/money", ["exports", "numeral", "boondmanager/utils/financial"], function (_exports, _numeral, _financial) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  /**
   * Displays a number on which the rates provided have been applied
   *
   * This helper require two arguments :
   *   - 1st arg : the initial number value
   *   - named arg 'sign' : the currency symbol
   *
   * Other named args are optional :
   *   - 'change'       : - default = 1
   *   - 'agencyChange' : - default = 1
   *   - 'precision'    : if precision === 0 or false, amount will be rounded (Math.round())
   *   - 'highlightNegativeValue'    : type boolean,if highlightNegativeValue === false, no red color is displayed for negative value
   *   - 'highlightIfNot' : type number,default = null, if highlightIfNot === n, red color is displayed for wrong value
  
   * Example:
   *
   * ```handlebars
   * {{money 10 sign="€"}}
   * ```
   *
   * WARNING! we do not properly use "numeral"! We only use its formatting properties.
   * Numeral language/locale never change : "en" (english). But we redefine the
   * delimiters and currency symbol at each call of this helper :/. Numeral can not
   * display numbers with another currency symbol than the one defined in the current
   * language/locale definition (here we use only "en")...
   *
   * @public
   * @method money
   * @for Ember.Templates.helpers
   */
  var _default = _exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),
    compute: function compute(params, hash) {
      var i18n = this.get('i18n');
      var value = params[0];
      var output = '';
      if (typeof value === 'undefined' || isNaN(value)) {
        output = '<span class="bm-money bm-text-danger warning">-</span>';
      } else {
        var klass = hash.class;
        var sign = hash.sign;
        var changeRate = hash.change || 1;
        var agenceRate = hash.agencyChange || 1;
        var precision = 2;
        if (hash.precision !== undefined) {
          precision = hash.precision;
        }
        var prefix = hash.prefix;
        var suffix = hash.suffix;
        var noHtml = hash.noHtml || false;
        var name = hash.name || '';
        var highlightNegativeValue = typeof hash.highlightNegativeValue === 'undefined' ? true : hash.highlightNegativeValue;
        var highlightIfNot = typeof hash.highlightIfNot === 'undefined' ? null : hash.highlightIfNot;
        var i18nKeyFormat = 'common:numeral.currency.default';
        var i18nKeyOptions;
        if (typeof precision === 'number' || typeof precision === 'boolean') {
          if (precision === 0 || precision === false) {
            i18nKeyFormat = 'common:numeral.currency.rounded';
          } else {
            i18nKeyFormat = 'common:numeral.currency.precision';
            i18nKeyOptions = {
              precision: '0'.repeat(precision)
            };
          }
        }
        var format = i18n.t(i18nKeyFormat, i18nKeyOptions);
        var amount = (0, _financial.roundAmountWithExchangeRates)((0, _financial.applyExchangeRates)(value, [1 / changeRate, 1 / agenceRate]), precision);
        var n = (0, _numeral.default)(amount);
        _numeral.default.localeData().delimiters = {
          decimal: i18n.t('common:numeral.delimiters.decimal'),
          //GRI common:number
          thousands: i18n.t('common:numeral.delimiters.thousands') //GRI common:number
        };

        // force currency symbol :/
        // cf https://github.com/adamwdraper/Numeral-js/issues/165
        _numeral.default.localeData().currency = {
          symbol: sign
        };
        var classes = ['bm-money'];
        if (amount < 0 && highlightNegativeValue || highlightIfNot !== null && amount !== highlightIfNot) {
          classes.push('bm-negative');
        }
        if (typeof klass !== 'undefined') {
          if (Ember.isArray(klass)) {
            classes.push.apply(classes, _toConsumableArray(klass));
          } else {
            classes.push(klass.split(' '));
          }
        }
        if (noHtml === false) {
          output = "<span class=\"".concat(classes.join(' '), "\" data-name=\"").concat(name, "\">") + (typeof prefix !== 'undefined' ? " <span class=\"bm-money_prefix\">".concat(prefix, "</span>") : '') + "".concat(n.format(format)) + (typeof suffix !== 'undefined' ? " <span class=\"bm-money_suffix\">".concat(suffix, "</span>") : '') + '</span>';
        } else {
          output = (typeof prefix !== 'undefined' ? " ".concat(prefix) : '') + "".concat(n.format(format)) + (typeof suffix !== 'undefined' ? " ".concat(suffix) : '');
        }
      }
      return new Ember.String.htmlSafe(output);
    },
    refreshText: Ember.observer('i18n._locale', function () {
      this.recompute();
    })
  });
});