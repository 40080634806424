define("boondmanager/settings/apps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.apps = void 0;
  var apps = _exports.apps = function apps(i18n) {
    return {
      advmodcandidates: {
        columns: {
          values: [{
            id: 'candidate',
            value: i18n.t('advmodcandidates:search.columns.values.candidate')
          }, {
            id: 'title',
            value: i18n.t('advmodcandidates:search.columns.values.title')
          }, {
            id: 'availability',
            value: i18n.t('advmodcandidates:search.columns.values.availability')
          }, {
            id: 'state',
            value: i18n.t('advmodcandidates:view.tabs.information.state')
          }, {
            id: 'mobilityAreas',
            value: i18n.t('advmodcandidates:search.columns.values.mobilityAreas')
          }, {
            id: 'details',
            value: i18n.t('advmodcandidates:search.columns.values.details')
          }, {
            id: 'numberOfActivePositionings',
            value: i18n.t('advmodcandidates:search.columns.values.numberOfActivePositionings')
          }, {
            id: 'updateDate',
            value: i18n.t('advmodcandidates:search.columns.values.updateDate')
          }, {
            id: 'activityAreas',
            value: i18n.t('advmodcandidates:search.columns.values.activityAreas')
          }, {
            id: 'expertiseAreas',
            value: i18n.t('advmodcandidates:search.columns.values.expertiseAreas')
          }, {
            id: 'tools',
            value: i18n.t('advmodcandidates:search.columns.values.tools')
          }, {
            id: 'evaluations',
            value: i18n.t('components:bmSearchFilters.evaluation_plural')
          }, {
            id: 'languages',
            value: i18n.t('advmodcandidates:search.columns.values.languages')
          }, {
            id: 'diplomas',
            value: i18n.t('advmodcandidates:search.columns.values.diplomas')
          }, {
            id: 'experience',
            value: i18n.t('advmodcandidates:search.columns.values.experience')
          }, {
            id: 'references',
            value: i18n.t('advmodcandidates:search.columns.values.references')
          }, {
            id: 'creationDate',
            value: i18n.t('advmodcandidates:search.columns.values.creationDate')
          }, {
            id: 'lastAction',
            value: i18n.t('advmodcandidates:search.columns.values.lastAction')
          }, {
            id: 'globalEvaluation',
            value: i18n.t('advmodcandidates:search.columns.values.globalEvaluation')
          }, {
            id: 'lastInterview',
            value: i18n.t('advmodcandidates:search.columns.values.lastInterview')
          }, {
            id: 'hrManager',
            value: i18n.t('advmodcandidates:search.columns.values.hrManager')
          }, {
            id: 'source',
            value: i18n.t('advmodcandidates:search.columns.values.source')
          }]
        },
        search: {
          perimeterManagersType: {
            values: [{
              id: 'main',
              value: i18n.t('advmodcandidates:search.perimeterManagersType.main')
            }, {
              id: 'hr',
              value: i18n.t('advmodcandidates:search.perimeterManagersType.hr')
            }]
          }
        }
      },
      advancedProjects: {
        settings: {
          calculationMode: {
            options: [{
              id: 'manual',
              value: i18n.t('advmodprojects:settings.calculationMode.options.manual')
            }, {
              id: 'signedTurnoverPerAdvancementPercentage',
              value: i18n.t('advmodprojects:settings.calculationMode.options.signedTurnoverPerAdvancementPercentage')
            }, {
              id: 'totalTurnoverProduction',
              value: i18n.t('advmodprojects:settings.calculationMode.options.totalTurnoverProduction')
            }]
          },
          remainsToBeDoneMode: {
            options: [{
              id: 'global',
              value: i18n.t('advmodprojects:settings.remainsToBeDoneMode.options.global')
            }, {
              id: 'groupments',
              value: i18n.t('advmodprojects:settings.remainsToBeDoneMode.options.groupments')
            }, {
              id: 'resources',
              value: i18n.t('advmodprojects:settings.remainsToBeDoneMode.options.resources')
            }]
          },
          turnoverProductionRateMode: {
            options: [{
              id: 'automatic',
              value: i18n.t('advmodprojects:settings.turnoverProductionRateMode.options.automatic')
            }, {
              id: 'manual',
              value: i18n.t('advmodprojects:settings.calculationMode.options.manual')
            }, {
              id: 'notUsed',
              value: i18n.t('advmodprojects:settings.turnoverProductionRateMode.options.notUsed')
            }]
          }
        }
      },
      celebrations: {
        intranetPerimeters: {
          options: [{
            id: 'myAgency',
            value: i18n.t('config:apps.celebrations.settings.intranetPerimeter.options.myAgency')
          }, {
            id: 'allAgencies',
            value: i18n.t('config:apps.celebrations.settings.intranetPerimeter.options.allAgencies')
          }]
        },
        filters: {
          periods: [{
            id: 'periodDynamic',
            value: i18n.t('common:dateAndTime.periodDynamic')
          }, {
            id: 'weekly',
            value: i18n.t('components:filterPeriod.range.thisWeek')
          }, {
            id: 'monthly',
            value: i18n.t('sepa:import.fields.month')
          }, {
            id: 'onePeriod',
            value: i18n.t('common:dateAndTime.customPeriod')
          }]
        }
      },
      extractbi: {
        frequency: {
          typeOf: {
            options: [{
              id: 'none',
              value: i18n.t('extractbi:frequency.typeOf.options.none')
            }, {
              id: 'monthly',
              value: i18n.t('extractbi:frequency.typeOf.options.monthly')
            }, {
              id: 'weekly',
              value: i18n.t('extractbi:frequency.typeOf.options.weekly')
            }, {
              id: 'daily',
              value: i18n.t('extractbi:frequency.typeOf.options.daily')
            }]
          },
          weeklyDays: {
            options: [{
              id: 'monday',
              value: i18n.t('extractbi:frequency.weeklyDays.options.monday')
            }, {
              id: 'tuesday',
              value: i18n.t('extractbi:frequency.weeklyDays.options.tuesday')
            }, {
              id: 'wednesday',
              value: i18n.t('extractbi:frequency.weeklyDays.options.wednesday')
            }, {
              id: 'thursday',
              value: i18n.t('extractbi:frequency.weeklyDays.options.thursday')
            }, {
              id: 'friday',
              value: i18n.t('extractbi:frequency.weeklyDays.options.friday')
            }, {
              id: 'saturday',
              value: i18n.t('extractbi:frequency.weeklyDays.options.saturday')
            }, {
              id: 'sunday',
              value: i18n.t('extractbi:frequency.weeklyDays.options.sunday')
            }]
          }
        }
      },
      gviewer: {
        viewerIntegration: {
          options: [{
            id: 'newWindow',
            value: i18n.t('gviewer:view.viewerIntegration.options.0.value')
          }, {
            id: 'insideBoondManager',
            value: i18n.t('gviewer:view.viewerIntegration.options.1.value')
          }]
        }
      },
      houraccounts: {
        filters: {
          validated: {
            options: [{
              id: '',
              value: i18n.t('houraccounts:filters.validated.options.0.value')
            }, {
              id: '1',
              value: i18n.t('dataclosing:view.tabs.expensesreports.activityExpenses.state.validated')
            }, {
              id: '0',
              value: i18n.t('houraccounts:filters.validated.options.2.value')
            }]
          }
        }
      },
      survey: {
        evaluationsList: [{
          id: 'none',
          value: i18n.t('survey:evaluationsList.0.value')
        }, {
          id: 'emoticons',
          value: i18n.t('survey:evaluationsList.1.value'),
          option: [{
            id: 'veryGood',
            value: i18n.t('survey:evaluationsList.1.option.0.value')
          }, {
            id: 'good',
            value: i18n.t('survey:evaluationsList.1.option.1.value')
          }, {
            id: 'bad',
            value: i18n.t('survey:evaluationsList.1.option.2.value')
          }, {
            id: 'veryBad',
            value: i18n.t('survey:evaluationsList.1.option.3.value')
          }]
        }, {
          id: 'yesNo',
          value: i18n.t('survey:evaluationsList.2.value'),
          option: [{
            id: 'yes',
            value: i18n.t('survey:evaluationsList.2.option.0.value')
          }, {
            id: 'no',
            value: i18n.t('survey:evaluationsList.2.option.1.value')
          }]
        }]
      },
      wadvantages: {
        filters: {
          periods: [{
            id: 'paid',
            value: i18n.t('wadvantages:filters.periods.paid')
          }]
        }
      },
      wcontracts: {
        filters: {
          periods: [{
            id: 'current',
            value: i18n.t('wcontracts:filters.periods.current')
          }]
        }
      },
      wdevis: {
        filters: {
          periods: [{
            id: 'created',
            value: i18n.t('wdevis:filters.periods.created')
          }, {
            id: 'validated',
            value: i18n.t('wdevis:filters.periods.validated')
          }]
        }
      },
      wemailing: {
        settings: {
          defaultOptions: [{
            id: 'default',
            value: i18n.t('wemailing:settings.defaultOptions.default')
          }, {
            id: 'customized',
            value: i18n.t('wemailing:settings.defaultOptions.customized')
          }],
          sendingType: {
            options: [{
              id: 'smtp',
              value: i18n.t('wemailing:settings.sendingTypes.smtp')
            }, {
              id: 'mandrill',
              value: i18n.t('wemailing:settings.sendingTypes.mandrill')
            }, {
              id: 'gmail',
              value: i18n.t('gmail:title')
            }, {
              id: 'microsoft',
              value: i18n.t('wemailing:settings.sendingTypes.microsoft')
            }]
          },
          serverPort: {
            options: [{
              id: 25,
              value: 25
            }, {
              id: 465,
              value: 465
            }, {
              id: 587,
              value: 587
            }]
          },
          serverProtocol: {
            options: [{
              id: 'none',
              value: i18n.t('wemailing:settings.serverProtocols.none')
            }, {
              id: 'ssl',
              value: i18n.t('wemailing:settings.serverProtocols.ssl')
            }, {
              id: 'tls',
              value: i18n.t('wemailing:settings.serverProtocols.tls')
            }]
          }
        },
        signature: {
          defaultOptions: [{
            id: false,
            value: i18n.t('wemailing:signatures.false')
          }, {
            id: true,
            value: i18n.t('wemailing:signatures.true')
          }]
        },
        templates: {
          state: {
            values: [{
              id: true,
              value: i18n.t('wemailing:templates.state.values.true')
            }, {
              id: false,
              value: i18n.t('wemailing:templates.state.values.false')
            }]
          },
          typeOf: {
            options: [{
              id: 'pushMessage',
              value: i18n.t('wemailing:templates.typesOf.pushMessage')
            }, {
              id: 'invoice',
              value: i18n.t('wemailing:templates.typesOf.invoice')
            }, {
              id: 'activity',
              value: i18n.t('wemailing:templates.typesOf.activity')
            }, {
              id: 'quotation',
              value: i18n.t('wdevis:title')
            }]
          }
        }
      },
      intranetaccounts: {
        subscriptions: [{
          id: 'active',
          value: i18n.t('intranetaccounts:subscriptions.0.value')
        }, {
          id: 'inactive',
          value: i18n.t('intranetaccounts:subscriptions.1.value')
        }]
      },
      saaseditor: {
        reporting: {
          indicators: [{
            reference: 'numberOfClients',
            title: i18n.t('saaseditor:reporting.indicators.numberOfClients.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.numberOfClients.tooltip')
          }, {
            reference: 'numberOfAccounts',
            title: i18n.t('saaseditor:reporting.indicators.numberOfAccounts.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.numberOfAccounts.tooltip')
          }, {
            reference: 'numberOfClientsWon',
            title: i18n.t('saaseditor:reporting.indicators.numberOfClientsWon.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.numberOfClientsWon.tooltip')
          }, {
            reference: 'numberOfClientsLost',
            title: i18n.t('saaseditor:reporting.indicators.numberOfClientsLost.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.numberOfClientsLost.tooltip')
          }, {
            reference: 'accountGrowth',
            title: i18n.t('saaseditor:reporting.indicators.accountGrowth.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.accountGrowth.tooltip')
          }, {
            typeOf: i18n.t('config:apps.saaseditor.reporting.indicators.typeOf')
          }, {
            reference: 'turnoverOfClientsWon',
            title: i18n.t('saaseditor:reporting.indicators.turnoverOfClientsWon.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.turnoverOfClientsWon.tooltip')
          }, {
            reference: 'turnoverOfClientsLost',
            title: i18n.t('saaseditor:reporting.indicators.turnoverOfClientsLost.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.turnoverOfClientsLost.tooltip')
          }, {
            reference: 'marginClientsWonLost',
            title: i18n.t('saaseditor:reporting.indicators.marginClientsWonLost.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.marginClientsWonLost.tooltip')
          }, {
            reference: 'turnoverOfInternalGrowth',
            title: i18n.t('saaseditor:reporting.indicators.turnoverOfInternalGrowth.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.turnoverOfInternalGrowth.tooltip')
          }, {
            typeOf: i18n.t('config:apps.saaseditor.reporting.indicators.typeOf')
          }, {
            reference: 'upSellingTurnover',
            title: i18n.t('saaseditor:reporting.indicators.upSellingTurnover.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.upSellingTurnover.tooltip')
          }, {
            reference: 'lifetimeValue',
            title: i18n.t('saaseditor:reporting.indicators.lifetimeValue.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.lifetimeValue.tooltip')
          }, {
            typeOf: i18n.t('config:apps.saaseditor.reporting.indicators.typeOf')
          }, {
            reference: 'monthlyRecurrentRevenue',
            title: i18n.t('saaseditor:reporting.indicators.monthlyRecurrentRevenue.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.monthlyRecurrentRevenue.tooltip')
          }, {
            reference: 'monthlyNetRevenue',
            title: i18n.t('saaseditor:reporting.indicators.monthlyNetRevenue.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.monthlyNetRevenue.tooltip')
          }, {
            reference: 'totalAcquisitionCost',
            title: i18n.t('saaseditor:reporting.indicators.totalAcquisitionCost.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.totalAcquisitionCost.tooltip')
          }, {
            typeOf: i18n.t('config:apps.saaseditor.reporting.indicators.typeOf')
          }, {
            reference: 'committedMonthlyRecurringRevenue',
            title: i18n.t('saaseditor:reporting.indicators.committedMonthlyRecurringRevenue.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.committedMonthlyRecurringRevenue.tooltip')
          }, {
            reference: 'customerAcquisitionCost',
            title: i18n.t('saaseditor:reporting.indicators.customerAcquisitionCost.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.customerAcquisitionCost.tooltip')
          }, {
            typeOf: i18n.t('config:apps.saaseditor.reporting.indicators.typeOf')
          }, {
            reference: 'lifetime',
            title: i18n.t('saaseditor:reporting.indicators.lifetime.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.lifetime.tooltip')
          }, {
            reference: 'paybackDuration',
            title: i18n.t('saaseditor:reporting.indicators.paybackDuration.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.paybackDuration.tooltip')
          }, {
            typeOf: i18n.t('config:apps.saaseditor.reporting.indicators.typeOf')
          }, {
            reference: 'churnRate',
            title: i18n.t('saaseditor:reporting.indicators.churnRate.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.churnRate.tooltip')
          }, {
            reference: 'churnRateNet',
            title: i18n.t('saaseditor:reporting.indicators.churnRateNet.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.churnRateNet.tooltip')
          }, {
            reference: 'customerAcquisitionCostRatio',
            title: i18n.t('saaseditor:reporting.indicators.customerAcquisitionCostRatio.title'),
            tooltip: i18n.t('saaseditor:reporting.indicators.customerAcquisitionCostRatio.tooltip')
          }],
          periods: [{
            id: 'monthly',
            value: i18n.t('saaseditor:reporting.periods.0.value')
          }, {
            id: 'quarterly',
            value: i18n.t('saaseditor:reporting.periods.1.value')
          }, {
            id: 'semiAnnual',
            value: i18n.t('saaseditor:reporting.periods.2.value')
          }, {
            id: 'annual',
            value: i18n.t('saaseditor:reporting.periods.3.value')
          }]
        }
      },
      sepa: {
        extractSepaFile: {
          extractTypes: [{
            id: 'salary',
            value: i18n.t('sepa:extractSepaFile.extractTypes.salary')
          }, {
            id: 'expenses',
            value: i18n.t('sepa:extractSepaFile.extractTypes.expenses')
          }, {
            id: 'advantages',
            value: i18n.t('sepa:extractSepaFile.extractTypes.advantages')
          }],
          extractPaymentDate: [{
            id: false,
            value: i18n.t('sepa:extractSepaFile.extractPaymentDate.false')
          }, {
            id: true,
            value: i18n.t('config:apps.sepa.extractSepaFile.extractPaymentDate.true')
          }]
        },
        contracts: {
          type: [{
            id: 'sepaContractsWithoutBankDetails',
            value: i18n.t('sepa:contracts.type.sepaContractsWithoutBankDetails')
          }, {
            id: 'sepaContractsWithBankDetails',
            value: i18n.t('sepa:contracts.type.sepaContractsWithBankDetails')
          }]
        },
        orders: {
          type: [{
            id: 'sepaOrdersWithoutBankDetailsOrUniqueReferenceMandate',
            value: i18n.t('sepa:orders.type.sepaOrdersWithoutBankDetailsOrUniqueReferenceMandate')
          }, {
            id: 'sepaOrdersWithBankDetailsAndUniqueReferenceMandate',
            value: i18n.t('sepa:orders.type.sepaOrdersWithBankDetailsAndUniqueReferenceMandate')
          }]
        },
        purchases: {
          import: {
            title: 'Importer les coordonnées bancaires des achats',
            profile: 'importer les coordonnées bancaires des achats',
            info: "Nous vous conseillons d'extraire les achats que vous souhaitez modifier puis de mettre à jour ce fichier avant de l'importer ci-dessous.",
            fields: {
              id: 'Référence interne',
              companyBankDetail: {
                title: 'Coordonnées bancaires du fournisseur',
                description: 'Coordonnées bancaires fournisseur - Compte',
                iban: 'Coordonnées bancaires fournisseur - IBAN',
                bic: 'Coordonnées bancaires fournisseur - BIC'
              }
            },
            notimported: {
              label: "L'erreur suivante s'est produite durant le dernier import des coordonnées bancaires des achats :",
              label_plural: 'Les {{count, total}} erreurs suivantes se sont produites durant le dernier import des coordonnées bancaires des achats :'
            }
          },
          type: [{
            id: 'sepaPurchasesWithoutBankDetails',
            value: i18n.t('sepa:purchases.type.sepaPurchasesWithoutBankDetails')
          }, {
            id: 'sepaPurchasesWithBankDetails',
            value: i18n.t('sepa:purchases.type.sepaPurchasesWithBankDetails')
          }]
        },
        import: {
          fields: {
            id: 'Référence interne',
            month: 'Mois',
            year: 'Année',
            transferSalary: 'Salaire net à verser',
            transferExpenses: 'Frais net à verser',
            transferAdvantages: 'Avantages net à verser',
            bankDetail: {
              title: 'Coordonnées bancaires de la ressource',
              description: 'Coordonnées bancaires ressource - Compte',
              iban: 'Coordonnées bancaires ressource - IBAN',
              bic: 'Coordonnées bancaires ressource - BIC'
            },
            agencyBankDetail: {
              title: "Coordonnées bancaires de l'agence juridique",
              description: 'Coordonnées bancaires agence - Compte',
              iban: 'Coordonnées bancaires agence - IBAN',
              bic: 'Coordonnées bancaires agence - BIC'
            }
          }
        },
        sequenceTypeOf: {
          options: [{
            id: 'first',
            value: i18n.t('sepa:sequenceTypeOf.options.first')
          }, {
            id: 'recurrent',
            value: i18n.t('sepa:sequenceTypeOf.options.recurrent')
          }, {
            id: 'oneOf',
            value: i18n.t('sepa:sequenceTypeOf.options.oneOf')
          }]
        }
      },
      wtriggersabsences: {
        import: {
          fields: {
            resource: {
              id: 'Ressource - Référence interne',
              reference: 'Ressource - Matricule'
            },
            workUnitType: {
              reference: "Type d'unité d'oeuvre - Code"
            },
            agency: {
              id: 'Agence - Référence interne'
            },
            year: 'Année'
          }
        }
      },
      wextractpayroll: {
        settings: {
          extraction: {
            columnsToExtract: {
              options: [{
                id: 'global1',
                value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1'),
                options: [{
                  id: 'dependsOn.id',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1Options.dependsOnId')
                }, {
                  id: 'dependsOn.reference',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1Options.dependsOnReference')
                }, {
                  id: 'dependsOn.lastName',
                  value: i18n.t('wemailing:templates.title')
                }, {
                  id: 'dependsOn.firstName',
                  value: i18n.t('wemailing:tinyMCE.firstname')
                }, {
                  id: 'dependsOn.typeOf',
                  value: i18n.t('wemailing:templates.typesOf.title')
                }, {
                  id: 'dependsOn.function',
                  value: i18n.t('wemailing:tinyMCE.function')
                }, {
                  id: 'typeOf',
                  value: i18n.t('sepa:view.tabs.contracts.contract.title')
                }, {
                  id: 'startDate',
                  value: i18n.t('houraccounts:table.start')
                }, {
                  id: 'endDate',
                  value: i18n.t('houraccounts:table.end')
                }, {
                  id: 'employeeType',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1Options.employeeType')
                }, {
                  id: 'classification',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1Options.classification')
                }, {
                  id: 'workingTimeType',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1Options.workingTimeType')
                }, {
                  id: 'workUnitRate',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1Options.workUnitRate')
                }, {
                  id: 'productionInternalAbsencesTimes',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1Options.productionInternalAbsencesTimes')
                }, {
                  id: 'productionInternalTimes',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1Options.productionInternalTimes')
                }, {
                  id: 'productionTimes',
                  value: i18n.t('advmodprojects:view.orders.production')
                }, {
                  id: 'dependsOn.agency',
                  value: i18n.t('intranetaccounts:view.agency')
                }, {
                  id: 'timesreports.state',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1Options.timesreportsState')
                }, {
                  id: 'expensesreports.state',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1Options.expensesreportsState')
                }, {
                  id: 'beginEndAmendment',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1Options.beginEndAmendment')
                }]
              }, {
                id: 'production',
                value: i18n.t('advmodprojects:view.orders.production'),
                options: [{
                  id: 'productionTimesPerTypePerAgency',
                  value: i18n.t('houraccounts:filters.validated.options.0.id')
                }]
              }, {
                id: 'global2',
                value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1'),
                options: [{
                  id: 'internalTimes',
                  value: i18n.t('wextractpayroll:internalTimes')
                }]
              }, {
                id: 'internal',
                value: i18n.t('wextractpayroll:internalTimes'),
                options: [{
                  id: 'internalTimesPerTypePerAgency',
                  value: i18n.t('houraccounts:filters.validated.options.0.id')
                }]
              }, {
                id: 'global3',
                value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1'),
                options: [{
                  id: 'absencesTimes',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global3Options.absencesTimes')
                }]
              }, {
                id: 'absence',
                value: i18n.t('houraccounts:table.absence.title'),
                options: [{
                  id: 'absencesTimesPerTypePerAgency',
                  value: i18n.t('houraccounts:filters.validated.options.0.id')
                }, {
                  id: 'absencesTimesDatesPerTypePerAgency',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.absenceOptions.absencesTimesDatesPerTypePerAgency')
                }]
              }, {
                id: 'global4',
                value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1'),
                options: [{
                  id: 'exceptionalTimes',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global4Options.exceptionalTimes')
                }]
              }, {
                id: 'exceptionalTimes',
                value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.exceptionalTimes'),
                options: [{
                  id: 'exceptionalTimesPerTypePerAgency',
                  value: i18n.t('houraccounts:filters.validated.options.0.id')
                }, {
                  id: 'exceptionalTimesDatesPerTypePerAgency',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.exceptionalTimesOptions.exceptionalTimesDatesPerTypePerAgency')
                }]
              }, {
                id: 'global5',
                value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1'),
                options: [{
                  id: 'exceptionalCalendars',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global5options.exceptionalCalendars')
                }]
              }, {
                id: 'exceptionalCalendars',
                value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.exceptionalCalendars'),
                options: [{
                  id: 'exceptionalCalendarsPerTypePerAgency',
                  value: i18n.t('houraccounts:filters.validated.options.0.id')
                }, {
                  id: 'exceptionalCalendarsDatesPerTypePerAgency',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.exceptionalTimesOptions.exceptionalTimesDatesPerTypePerAgency')
                }]
              }, {
                id: 'global6',
                value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1'),
                options: [{
                  id: 'currency',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global6Options.currency')
                }, {
                  id: 'expensesToPay',
                  value: i18n.t('sepa:extractSepaFile.extractTypes.expenses')
                }]
              }, {
                id: 'expenses',
                value: i18n.t('sepa:extractSepaFile.extractTypes.expenses'),
                options: [{
                  id: 'kilometricExpensesToPayPerAgency',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.expensesOptions.kilometricExpensesToPayPerAgency')
                }, {
                  id: 'expensesToPayPerTypePerAgency',
                  value: i18n.t('houraccounts:filters.validated.options.0.id')
                }]
              }, {
                id: 'global7',
                value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1'),
                options: [{
                  id: 'monthlySalary',
                  value: i18n.t('wcontracts:salary')
                }]
              }, {
                id: 'advantages',
                value: i18n.t('sepa:extractSepaFile.extractTypes.advantages'),
                options: [{
                  id: 'advantagesToPay',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.advantagesOptions.advantagesToPay')
                }, {
                  id: 'advantagesToPayQuantityPerTypePerAgency',
                  value: i18n.t('wadvantages:quantity')
                }, {
                  id: 'advantagesToPayEmployeeAmountPerTypePerAgency',
                  value: i18n.t('wadvantages:costPaid')
                }, {
                  id: 'advantagesToPayParticipationAmountPerTypePerAgency',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.advantagesOptions.advantagesToPayParticipationAmountPerTypePerAgency')
                }]
              }, {
                id: 'global8',
                value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global1'),
                options: [{
                  id: 'expensesAlreadyAdvanced',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global8Options.expensesAlreadyAdvanced')
                }, {
                  id: 'payrollComments',
                  value: i18n.t('wextractpayroll:payrollComments')
                }]
              }, {
                id: 'workplace',
                value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.workplace'),
                options: [{
                  id: 'workplacesTimesPerTypePerAgency',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.workplaceOptions.workplacesTimesPerTypePerAgency')
                }, {
                  id: 'workplacesTimesDatesPerTypePerAgency',
                  value: i18n.t('wexceptionnalactivity:dates')
                }]
              }, {
                id: 'global9',
                value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global9'),
                options: [{
                  id: 'workplacesTimesAgency',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global9Options.workplacesTimesAgency')
                }, {
                  id: 'workplacesTimesCustomerSite',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global9Options.workplacesTimesCustomerSite')
                }, {
                  id: 'workplacesTimesRemote',
                  value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global9Options.workplacesTimesRemote')
                }]
              }]
            }
          }
        }
      },
      wplanproduction: {
        dataTypes: {
          options: [{
            id: '0',
            value: i18n.t('resourceplanner:planificationTitle'),
            type: i18n.t('apps.wplanproduction.dataTypes.options.0.type')
          }, {
            id: '1',
            value: i18n.t('wplanproduction:dataTypes.absences'),
            type: i18n.t('apps.wplanproduction.dataTypes.options.1.type')
          }, {
            id: '2',
            value: i18n.t('wplanproduction:dataTypes.regularTimes'),
            type: i18n.t('apps.wplanproduction.dataTypes.options.2.type')
          }, {
            id: '3',
            value: i18n.t('wplanproduction:dataTypes.production'),
            type: i18n.t('config:apps.wplanproduction.dataTypes.options.type')
          }]
        },
        filters: {
          availabilitySorting: [{
            id: '',
            value: i18n.t('wplanproduction:filters.availabilitySorting.none')
          }, {
            id: 'asc',
            value: i18n.t('wplanproduction:filters.availabilitySorting.asc')
          }, {
            id: 'desc',
            value: i18n.t('wplanproduction:filters.availabilitySorting.desc')
          }]
        },
        productionPlansState: [{
          id: 'signedDelivery',
          value: i18n.t('advmodprojects:view.orders.production')
        }, {
          id: 'forecastDelivery',
          value: i18n.t('wplanproduction:productionPlansState.forecastDelivery')
        }, {
          id: 'internal',
          value: i18n.t('wextractpayroll:internalTimes')
        }, {
          id: 'absence',
          value: i18n.t('houraccounts:table.absence.title')
        }, {
          id: 'absence-report',
          value: i18n.t('models:absencesreport.name')
        }, {
          id: 'overloaded-day',
          value: i18n.t('wplanproduction:productionPlansState.overloaded-day')
        }, {
          id: 'underloaded-day',
          value: i18n.t('wplanproduction:productionPlansState.underloaded-day')
        }]
      },
      wbackupbdd: {
        settings: {
          dataToBackup: {
            options: [{
              id: 'databaseAndGed',
              value: i18n.t('wbackupbdd:settings.dataToBackup.databaseAndGed')
            }, {
              id: 'database',
              value: i18n.t('wbackupbdd:settings.dataToBackup.database')
            }, {
              id: 'ged',
              value: i18n.t('wbackupbdd:settings.dataToBackup.ged')
            }]
          },
          frequency: {
            monthly: {
              options: [{
                id: 1,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.1')
              }, {
                id: 2,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.2')
              }, {
                id: 3,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.3')
              }, {
                id: 4,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.4')
              }, {
                id: 5,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.5')
              }, {
                id: 6,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.6')
              }, {
                id: 7,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.7')
              }, {
                id: 8,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.8')
              }, {
                id: 9,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.9')
              }, {
                id: 10,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.10')
              }, {
                id: 11,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.11')
              }, {
                id: 12,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.12')
              }, {
                id: 13,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.13')
              }, {
                id: 14,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.14')
              }, {
                id: 15,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.15')
              }, {
                id: 16,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.16')
              }, {
                id: 17,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.17')
              }, {
                id: 18,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.18')
              }, {
                id: 19,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.19')
              }, {
                id: 20,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.20')
              }, {
                id: 21,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.21')
              }, {
                id: 22,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.22')
              }, {
                id: 23,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.23')
              }, {
                id: 24,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.24')
              }, {
                id: 25,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.25')
              }, {
                id: 26,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.26')
              }, {
                id: 27,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.27')
              }, {
                id: 28,
                value: i18n.t('config:agency.activityExpenses.creationAndMailingDate.options.28')
              }]
            }
          }
        }
      },
      wcreateactivitydocuments: {
        document: {
          documentTypeOf: {
            options: [{
              id: 'timesreport',
              value: i18n.t('wcreateactivitydocuments:document.documentTypeOf.options.0.value')
            }, {
              id: 'expensesreport',
              value: i18n.t('wcreateactivitydocuments:document.documentTypeOf.options.1.value')
            }]
          }
        }
      },
      dataclosing: {
        filterPeriod: {
          range: {
            thisYear: {
              id: 5,
              value: 'Année fiscale en cours'
            },
            previousyear: {
              id: 6,
              value: 'Année fiscale précédente'
            }
          }
        }
      },
      wexceptionnalactivity: {
        companies: {
          type: [{
            id: 'withoutScales',
            value: i18n.t('wexceptionnalactivity:companies.type.withoutScales')
          }, {
            id: 'withScales',
            value: i18n.t('wexceptionnalactivity:companies.type.withScales')
          }]
        }
      },
      wpostproduction: {
        correlatedState: [{
          id: 'oneCorrelatedOrder',
          value: i18n.t('wpostproduction:correlatedStates.oneCorrelatedOrder')
        }, {
          id: 'conflictOrders',
          value: i18n.t('wpostproduction:correlatedStates.conflictOrders')
        }, {
          id: 'noOrder',
          value: i18n.t('wpostproduction:correlatedStates.noOrder')
        }, {
          id: 'multipleStates',
          value: i18n.t('wpostproduction:correlatedStates.multipleStates')
        }],
        endValidationState: [{
          id: 'multipleStates',
          value: i18n.t('wpostproduction:correlatedStates.multipleStates')
        }, {
          id: 'missingSignedDocuments',
          value: i18n.t('wpostproduction:endValidationStates.missingSignedDocuments')
        }]
      },
      wsageinterface: {
        extract: {
          accountingType: [{
            id: 'vatIncludingTax',
            value: i18n.t('common:financial.inclTax.abbr')
          }, {
            id: 'vatExcludingTax',
            value: i18n.t('common:financial.exclTax.abbr')
          }],
          payrollType: {
            default: [{
              id: 'activitySalaryExpensesAndAdvantages',
              value: i18n.t('wsageinterface:extract.payrollTypes.default.activitySalaryExpensesAndAdvantages')
            }, {
              id: 'activity',
              value: i18n.t('wsageinterface:extract.payrollTypes.default.activity')
            }, {
              id: 'salaryExpensesAndAdvantages',
              value: i18n.t('wsageinterface:extract.payrollTypes.default.salaryExpensesAndAdvantages')
            }],
            silae: [{
              id: 'activityAbsencesAndVariable',
              value: i18n.t('wsageinterface:extract.payrollTypes.silae.activityAbsencesAndVariable')
            }, {
              id: 'activity',
              value: i18n.t('wsageinterface:extract.payrollTypes.default.activity')
            }, {
              id: 'absences',
              value: i18n.t('wextractpayroll:settings.extraction.columnsToExtract.options.global3Options.absencesTimes')
            }, {
              id: 'variable',
              value: i18n.t('wsageinterface:extract.payrollTypes.silae.variable')
            }]
          }
        },
        companies: {
          type: [{
            id: 'sageCompaniesWithoutThirdAccount',
            value: i18n.t('wsageinterface:companies.type.sageCompaniesWithoutThirdAccount')
          }, {
            id: 'sageCompaniesWithThirdAccount',
            value: i18n.t('wsageinterface:companies.type.sageCompaniesWithThirdAccount')
          }]
        },
        employees: {
          type: [{
            id: 'sageEmployeesWithoutAccount',
            value: i18n.t('wsageinterface:employees.type.sageEmployeesWithoutAccount')
          }, {
            id: 'sageEmployeesWithAccount',
            value: i18n.t('wsageinterface:employees.type.sageEmployeesWithAccount')
          }]
        },
        settings: {
          globalConfiguration: {
            software: {
              accounting: {
                options: [{
                  id: 'sageL100',
                  value: i18n.t('wsageinterface:settings.globalConfiguration.software.accounting.sageL100')
                }, {
                  id: 'sagePNM',
                  value: i18n.t('wsageinterface:settings.globalConfiguration.software.accounting.sagePNM')
                }, {
                  id: 'sageCoala',
                  value: i18n.t('wsageinterface:settings.globalConfiguration.software.accounting.sageCoala')
                }, {
                  id: 'CEGID',
                  value: i18n.t('wsageinterface:settings.globalConfiguration.software.accounting.CEGID')
                }]
              },
              payroll: {
                options: [{
                  id: 'sageL100',
                  value: i18n.t('wsageinterface:settings.globalConfiguration.software.accounting.sageL100')
                }, {
                  id: 'sageCoala',
                  value: i18n.t('wsageinterface:settings.globalConfiguration.software.accounting.sageCoala')
                }, {
                  id: 'SILAE',
                  value: i18n.t('wsageinterface:settings.globalConfiguration.software.payroll.SILAE')
                }]
              }
            }
          },
          payroll: {
            elementToExtract: {
              expense: {
                options: [{
                  id: 0,
                  value: i18n.t('sepa:view.tabs.payments.amountIncludingTax')
                }]
              },
              advantage: {
                options: [{
                  id: 1,
                  value: i18n.t('wsageinterface:settings.payroll.elementToExtract.advantage.totalAmount')
                }, {
                  id: 2,
                  value: i18n.t('wadvantages:quantity')
                }, {
                  id: 3,
                  value: i18n.t('wsageinterface:settings.payroll.elementToExtract.advantage.unitAmount')
                }]
              },
              workUnitType: {
                options: [{
                  id: 4,
                  value: i18n.t('wsageinterface:settings.payroll.elementToExtract.workUnitType.days')
                }, {
                  id: 5,
                  value: i18n.t('wsageinterface:settings.payroll.elementToExtract.workUnitType.hours')
                }]
              }
            }
          }
        }
      }
    };
  };
});