define("boondmanager/utils/dom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getOuterWidth = getOuterWidth;
  /**
   * Get outer width of an element
   * @param element {HTMLElement}
   * @param withMargin {boolean} includes margin; Default to false
   */
  function getOuterWidth(element) {
    var withMargin = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    if (!(element instanceof HTMLElement)) {
      throw new Error('element is not an HTMLElement');
    }
    var initialDisplayValue = element.style.display;
    if (!element.checkVisibility()) {
      element.style.display = 'block';
    }
    var width = element.getBoundingClientRect().width;
    if (withMargin) {
      var computed = window.getComputedStyle(element);
      var marginLeft = parseFloat(computed.marginLeft || 0);
      var marginRight = parseFloat(computed.marginRight || 0);
      width += marginLeft + marginRight;
    }
    element.style.display = initialDisplayValue;
    return width;
  }
});