define("boondmanager/pods/components/octane/bm-feature-is-enabled/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "dNVBXLS3",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"isEnabled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[11,\"div\"],[24,0,\"bmc-feature-is-enabled\"],[17,1],[12],[18,2,null],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-feature-is-enabled/template.hbs"
  });
});