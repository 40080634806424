define("boondmanager/pods/components/octane/bm-advanced-reporting-dashboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "VEl9+0/m",
    "block": "{\"symbols\":[\"&attrs\",\"@params\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-advanced-reporting-dashboard\"],[17,1],[4,[38,0],[[32,0,[\"onInsert\"]]],null],[4,[38,1],[[32,0,[\"onUpdate\"]],[32,2]],null],[12],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-advanced-reporting-dashboard/template.hbs"
  });
});