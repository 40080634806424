define("boondmanager/pods/components/octane/bm-beta-badge/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "yy07c43X",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"span\"],[24,0,\"bm-chip bm-chip_square bm-chip_beta\"],[17,1],[12],[2,\"Beta\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "boondmanager/pods/components/octane/bm-beta-badge/template.hbs"
  });
});