define("boondmanager/pods/components/octane/bm-layout-tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "REaRNKMk",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-layout-profile-tabs\"],[17,1],[4,[38,0],[[32,0,[\"onInsert\"]]],null],[4,[38,1],[[32,0,[\"onResize\"]],[32,0,[\"appsTabsIsFulFilled\"]]],null],[4,[38,2],[[32,0,[\"onResize\"]]],null],[12],[2,\"\\n\\t\"],[10,\"nav\"],[14,0,\"bmc-layout-profile-tabs-items\"],[12],[18,2,null],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"bmc-layout-profile-tabs-dropdown nav-item\"],[12],[2,\"\\n\\t\\t\"],[10,\"button\"],[14,0,\"bmc-layout-profile-tabs-dropdown-label\"],[14,\"data-toggle\",\"dropdown\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,3],[\"bmi-clone\"],null]],[2,\"\\n\\t\\t\\t...\\n\\t\\t\\t\"],[1,[30,[36,3],[\"bmi-caret-down bmc-layout-profile-tabs-dropdown-carret\"],null]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\"],[10,\"div\"],[14,0,\"bmc-layout-profile-tabs-dropdown-menu dropdown-menu\"],[14,\"role\",\"menu\"],[12],[2,\"\\n\\t\\t\\t\"],[18,2,null],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\",\"did-resize\",\"icon\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-layout-tabs/template.hbs"
  });
});