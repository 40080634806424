define("boondmanager/utils/positioningSuggest", ["exports", "boondmanager/utils/bm"], function (_exports, _bm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cleanFilters = cleanFilters;
  _exports.convertSettingsToFilters = convertSettingsToFilters;
  _exports.perimeterSettingToFilter = perimeterSettingToFilter;
  /**
   * Convertion de la configuration des périmètres en filtres frontend
   * @param {Object} configuration Current settings
   * @param {String} module Module
   * @returns {Object}
   */
  function perimeterSettingToFilter(configuration, module) {
    switch (module) {
      case _bm.MODULE_CANDIDATES:
        return {
          perimeterDynamic: configuration.get('defaultPositioningSuggestCandidatesPerimeterDynamic'),
          perimeterManagers: configuration.get('defaultPositioningSuggestCandidatesPerimeterManagers'),
          perimeterAgencies: configuration.get('defaultPositioningSuggestCandidatesPerimeterAgencies'),
          perimeterBusinessUnits: configuration.get('defaultPositioningSuggestCandidatesPerimeterBusinessUnits'),
          perimeterPoles: configuration.get('defaultPositioningSuggestCandidatesPerimeterPoles')
        };
      case _bm.MODULE_RESOURCES:
        return {
          perimeterDynamic: configuration.get('defaultPositioningSuggestResourcesPerimeterDynamic'),
          perimeterManagers: configuration.get('defaultPositioningSuggestResourcesPerimeterManagers'),
          perimeterAgencies: configuration.get('defaultPositioningSuggestResourcesPerimeterAgencies'),
          perimeterBusinessUnits: configuration.get('defaultPositioningSuggestResourcesPerimeterBusinessUnits'),
          perimeterPoles: configuration.get('defaultPositioningSuggestResourcesPerimeterPoles')
        };
    }
    return {};
  }

  /**
   * Convertion de la configuration des suggestions de profiles en filtres frontend
   * @param {Opportunity} opportunity Modèle besoin
   * @param {settings} settings Service settings
   * @param {currentUser} currentUser
   * @returns {*[]}
   */
  function convertSettingsToFilters(opportunity, settings, currentUser) {
    var configuration = settings.customer.positioningSuggest;
    var adminConf = true;

    // Vérifier si l'utilisateur courrant à une configuration sinon prendre la config global
    if (currentUser.get('positioningSuggest')) {
      configuration = currentUser.get('positioningSuggest');
      adminConf = false;
    }
    var candidateFilters = {
      module: 'candidates',
      params: {
        candidateTypes: configuration.defaultPositioningSuggestExcludedCandidatesTypes.length > 0 ? settings.customer.typeOf.resource.filter(function (type) {
          return !configuration.defaultPositioningSuggestExcludedCandidatesTypes.includes(type.id) && type.isEnabled;
        }).map(function (type) {
          return type.id;
        }) : [],
        candidateStates: configuration.defaultPositioningSuggestExcludedCandidatesStates.length > 0 ? settings.customer.state.candidate.filter(function (type) {
          return !configuration.defaultPositioningSuggestExcludedCandidatesStates.includes(type.id) && type.isEnabled;
        }).map(function (type) {
          return type.id;
        }) : []
      }
    };
    if (adminConf) {
      candidateFilters.params.perimeterDynamic = [configuration.defaultPositioningSuggestCandidatesPerimeter];
    } else {
      candidateFilters.params = Ember.assign(candidateFilters.params, perimeterSettingToFilter(configuration, _bm.MODULE_CANDIDATES));
    }
    var resourceFilters = {
      module: 'resources',
      params: {
        resourceTypes: configuration.defaultPositioningSuggestExcludedResourcesTypes.length > 0 ? settings.customer.typeOf.resource.filter(function (type) {
          return !configuration.defaultPositioningSuggestExcludedResourcesTypes.includes(type.id) && type.isEnabled;
        }).map(function (type) {
          return type.id;
        }) : [],
        resourceStates: configuration.defaultPositioningSuggestExcludedResourcesStates.length > 0 ? settings.customer.state.resource.filter(function (type) {
          return !configuration.defaultPositioningSuggestExcludedResourcesStates.includes(type.id) && type.isEnabled;
        }).map(function (type) {
          return type.id;
        }) : []
      }
    };
    if (adminConf) {
      resourceFilters.params.perimeterDynamic = [configuration.defaultPositioningSuggestResourcesPerimeter];
    } else {
      resourceFilters.params = Ember.assign(resourceFilters.params, perimeterSettingToFilter(configuration, _bm.MODULE_RESOURCES));
    }

    // Configurable uniquement dans les paramètres globaux
    var filtersFromOpportunity = configuration.defaultPositioningSuggestFiltersFromOpportunity;
    if (filtersFromOpportunity) {
      filtersFromOpportunity.forEach(function (column) {
        var levels = [5, 4, 3, 2, 1];
        var tools = opportunity.get('tools') || [];
        var toolsFilter = [];
        switch (column) {
          case 'tools':
            tools.forEach(function (tool) {
              toolsFilter = toolsFilter.concat(levels.map(function (level) {
                return tool + '|' + level;
              }));
            });
            candidateFilters.params.tools = toolsFilter;
            resourceFilters.params.tools = toolsFilter;
            break;
          case 'place':
            candidateFilters.params.mobilityAreas = opportunity.get('place') ? [opportunity.get('place')] : [];
            resourceFilters.params.mobilityAreas = opportunity.get('place') ? [opportunity.get('place')] : [];
            break;
          case 'expertiseArea':
            candidateFilters.params.expertiseAreas = opportunity.get('expertiseArea') !== '-1' ? [opportunity.get('expertiseArea')] : [];
            resourceFilters.params.expertiseAreas = opportunity.get('expertiseArea') !== '-1' ? [opportunity.get('expertiseArea')] : [];
            break;
          default:
            candidateFilters.params[column] = opportunity.get(column);
            resourceFilters.params[column] = opportunity.get(column);
            break;
        }
      });
    }
    var keywords = [];
    configuration.defaultPositioningSuggestKeywordsFromOpportunity.forEach(function (column) {
      var configKey = column;
      var hasConfig = false;
      var findByOptions = false;
      switch (column) {
        case 'activityAreas':
          configKey = 'activityArea';
          hasConfig = true;
          findByOptions = true;
          break;
        case 'tools':
          configKey = 'tool';
          hasConfig = true;
          break;
        case 'expertiseArea':
          hasConfig = true;
          break;
        default:
          break;
      }
      var options = settings.customer[configKey];
      if (Ember.isArray(opportunity.get(column))) {
        opportunity.get(column).forEach(function (val) {
          var keyword = undefined;
          if (hasConfig) {
            keyword = options.find(function (opt) {
              if (findByOptions) {
                if (opt.option) {
                  return opt.option.find(function (subOpt) {
                    return subOpt.id === val;
                  });
                }
              } else {
                return opt.id === val;
              }
            });
            if (keyword) {
              if (findByOptions) {
                var subOpt = keyword.option.find(function (subOpt) {
                  return subOpt.id === val;
                });
                if (subOpt) {
                  keyword = subOpt.value;
                }
              } else {
                keyword = keyword.value;
              }
            }
          } else {
            keyword = val;
          }
          if (keyword && keyword != -1) {
            keywords.push("\"".concat(keyword, "\""));
          }
        });
      } else {
        var keyword = undefined;
        if (hasConfig) {
          keyword = options.find(function (opt) {
            if (findByOptions) {
              if (opt.option) {
                return opt.option.find(function (subOpt) {
                  return subOpt.id === opportunity.get(column);
                });
              }
            } else {
              return opt.id === opportunity.get(column);
            }
          });
          if (keyword) {
            if (findByOptions) {
              var subOpt = keyword.option.find(function (subOpt) {
                return subOpt.id === opportunity.get(column);
              });
              if (subOpt) {
                keyword = subOpt.value;
              }
            } else {
              keyword = keyword.value;
            }
          }
        } else {
          keyword = opportunity.get(column);
        }
        if (keyword && keyword != -1) {
          keywords.push("\"".concat(keyword, "\""));
        }
      }
    });
    if (keywords.length > 0) {
      candidateFilters.params.keywords = keywords.join(' ');
      resourceFilters.params.keywords = keywords.join(' ');
    }
    var final = Ember.A([]);
    final.pushObject(candidateFilters);
    final.pushObject(resourceFilters);
    return final;
  }

  /**
   * Nettoyer les filtres
   * @param {object} filters Filtres à nettoyer
   * @param {settings} settings Service settings
   * @returns {}
   */
  function cleanFilters(filters, settings) {
    var conf = null;
    Object.keys(filters).forEach(function (k) {
      switch (k) {
        case 'candidateTypes':
        case 'resourceTypes':
          conf = settings.customer.typeOf.resource.filter(function (type) {
            return type.isEnabled;
          });
          filters[k] = conf.filter(function (type) {
            return filters[k].includes(type.id);
          }).map(function (type) {
            return type.id;
          });
          if (k == 'candidateTypes' && filters[k].length) {
            filters[k].push(-1);
          }
          break;
        case 'candidateStates':
          conf = settings.customer.state.candidate.filter(function (type) {
            return type.isEnabled;
          });
          filters[k] = conf.filter(function (type) {
            return filters[k].includes(type.id);
          }).map(function (type) {
            return type.id;
          });
          break;
        case 'resourceStates':
          conf = settings.customer.state.resource.filter(function (type) {
            return type.isEnabled;
          });
          filters[k] = conf.filter(function (type) {
            return filters[k].includes(type.id);
          }).map(function (type) {
            return type.id;
          });
          break;
      }
    });
    var cleanedFilters = {};
    Object.keys(filters).forEach(function (key) {
      var val = filters[key];
      if (val !== undefined && val.length > 0) {
        cleanedFilters[key] = val;
      }
    });
    return cleanedFilters;
  }
});