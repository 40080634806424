define("boondmanager/pods/components/octane/bm-breadcrumb/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mDVy/Gxa",
    "block": "{\"symbols\":[\"part\",\"index\",\"&attrs\"],\"statements\":[[11,\"nav\"],[24,0,\"bmc-breadcrumb\"],[17,3],[4,[38,4],[[32,0,[\"didInsert\"]]],null],[12],[2,\"\\n\\t\"],[10,\"ol\"],[14,0,\"page-breadcrumb\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,0,[\"breadcrumbParts\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[10,\"li\"],[15,0,[30,[36,1],[[32,1,[\"className\"]],\" page\"],null]],[15,\"aria-current\",[30,[36,3],[[30,[36,2],[[32,1],[32,0,[\"breadcrumbParts\",\"lastObject\"]]],null],\"page\"],null]],[15,\"data-name\",[30,[36,1],[\"component-bm-breadcrumb\",[32,2]],null]],[12],[2,\"\\n\"],[6,[37,3],[[32,1,[\"isLinkable\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[8,\"link-to\",[[24,0,\"bm-link-secondary\"]],[[\"@route\",\"@models\",\"@query\"],[[32,1,[\"link\",\"route\"]],[32,1,[\"link\",\"models\"]],[32,1,[\"link\",\"query\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[32,1,[\"value\"]]],[2,\"\\n\\t\\t\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\\t\\t\"],[1,[30,[36,0],[\"bmi-breadcrumb-separator\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[32,1,[\"value\"]]],[2,\"\\n\\t\\t\\t\\t\\t\"],[1,[30,[36,0],[\"bmi-breadcrumb-separator\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[32,1,[\"featureFlag\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[8,\"octane/bm-beta-badge\",[],[[\"@featureFlag\"],[[32,1,[\"featureFlag\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"icon\",\"concat\",\"eq\",\"if\",\"did-insert\",\"-track-array\",\"each\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-breadcrumb/template.hbs"
  });
});