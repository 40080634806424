define("boondmanager/pods/components/octane/bm-field/flags/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2oJy4q97",
    "block": "{\"symbols\":[\"@name\",\"&attrs\",\"@model\"],\"statements\":[[11,\"div\"],[16,0,[31,[[32,0,[\"classNames\"]]]]],[17,2],[4,[38,4],[[35,3]],null],[4,[38,6],[[35,5],[32,3]],null],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,7],[[32,0,[\"isLoading\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-icon\"],[16,\"data-name\",[30,[36,0],[\"button-toggle-\",[32,1]],null]]],[[\"@iconOnly\",\"@onClick\"],[\"bmi-flag\",[32,0,[\"toggleShow\"]]]],null],[2,\"\\n\"],[6,[37,2],[[32,0,[\"showFlagInput\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\"],[8,\"octane/bm-field/select\",[[16,\"data-name\",[30,[36,0],[\"input-\",[32,1]],null]],[24,0,\"dropdown-flags\"]],[[\"@options\",\"@selected\",\"@multiple\",\"@displayNbSelected\",\"@onChange\",\"@disabled\"],[[32,0,[\"options\"]],[32,0,[\"selected\"]],true,false,[32,0,[\"updateSelectedFlags\"]],[32,0,[\"disabled\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-icon\"],[16,\"data-name\",[30,[36,0],[\"button-toggle-\",[32,1]],null]],[16,\"aria-label\",[30,[36,1],[\"models:modules.attributes.flags.name\"],null]]],[[\"@iconOnly\",\"@disabled\"],[\"bmi-spinner fa-pulse\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,1],[\"flag:title_plural\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"t\",\"if\",\"onInsert\",\"did-insert\",\"loadFlags\",\"did-update\",\"not\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/flags/template.hbs"
  });
});