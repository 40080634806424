define("boondmanager/adapters/application", ["exports", "boondmanager/utils/logger", "ember-data", "ember-data-url-templates", "boondmanager/config/environment", "boondmanager/utils", "boondmanager/adapters/errors/version-error"], function (_exports, _logger, _emberData, _emberDataUrlTemplates, _environment, _utils, _versionError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  /**
   * Classe définissant l'adaptateur JSON API par défaut.
   *
   * * @example
   *     App.ApplicationAdapter = DS.JSONAPIAdapter.extend({});
   *
   * @class ApplicationAdapter
   * @extends DS.JSONAPIAdapter
   * @namespace  Ember
   * @module  Models
   */
  var _default = _exports.default = _emberData.default.JSONAPIAdapter.extend(_emberDataUrlTemplates.default, {
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    i18n: Ember.inject.service(),
    notify: Ember.inject.service(),
    apmMetrics: Ember.inject.service(),
    host: _environment.default.apiURL,
    namespace: _environment.default.apiNS,
    // urlTemplate has {/endpoint*} to allow for multiple endpoints
    // cf pods/private/settings/resource/intranet/route : endpoint: ['settings', 'intranet']
    urlTemplate: '{+host}/api{/middlepoint}/{pathForType}{/id}{/endpoint*}{.format}{?query*}',
    updateRecordUrlTemplate: '{+host}/api{/middlepoint}/{pathForType}{/id}{/endpoint*}{?query*}',
    createRecordUrlTemplate: '{+host}/api{/middlepoint}/{pathForType}{?query*}',
    // headers to add to all ajax requests
    headers: Object.freeze({
      'x-front-boondmanager': 'ember',
      'x-front-version': _environment.default.version
    }),
    /**
     @method ajaxOptions
     @private
     @param {String} url
     @param {String} method The request type GET, POST, PUT, DELETE etc.
     @param {Object} options
     @return {Object}
    */
    ajaxOptions: function ajaxOptions(url, method /*, options*/) {
      var _this = this;
      var opts = this._super.apply(this, arguments);
      opts.crossDomain = true;
      opts.xhrFields = {
        withCredentials: true
      };

      // tracing (apm-rum)
      var name = method + ' ' + url.replace(_environment.default.apiURL, '').replace(/(\/)([0-9]+)(\/|$)/g, '$1@id$3').replace(/\?.*$/, '');
      var span = this.get('apmMetrics').startSpan(name, 'external.http.query');
      if (span) {
        var traceId = this.get('apmMetrics').getDistributedTracingID(span);
        if (traceId) {
          opts.headers['traceparent'] = traceId;
        }
        span.context = {
          http: {
            url: url,
            method: method,
            status_code: null
          }
        };
      }
      var onComplete = opts.complete;
      opts.complete = function (response, status) {
        if (span) {
          span.context.http.status_code = response.status;
          span.end();
          if (onComplete) onComplete(response, status);
        }
      };

      // old 'ember-simple-auth/mixins/data-adapter-mixin'
      var beforeSend = opts.beforeSend;
      opts.beforeSend = function (xhr) {
        _this.authorize(xhr);
        if (beforeSend) {
          beforeSend(xhr);
        }
      };
      return opts;
    },
    authorize: function authorize(xhr) {
      var csrf = this.get('session.data.authenticated.meta.csrf');
      if (!Ember.isEmpty(csrf)) {
        xhr.setRequestHeader('x-csrf-boondmanager', csrf);
      }
    },
    // cf https://github.com/amiel/ember-data-url-template/issues/22
    // + ember-data-url-template redefine sortQueryParams() so it returns an empty
    //   object {} because https://github.com/emberjs/data/pull/3099.
    //   see ds-improved-ajax too
    //
    // SO we redefine sortQueryParams here to have our query params...
    sortQueryParams: function sortQueryParams(obj) {
      var keys = Object.keys(obj);
      var len = keys.length;
      if (len < 2) {
        return obj;
      }
      var newQueryParams = {};
      var sortedKeys = keys.sort();
      for (var i = 0; i < len; i++) {
        newQueryParams[sortedKeys[i]] = obj[sortedKeys[i]];
      }
      return newQueryParams;
    },
    urlSegments: Object.freeze({
      query: function query(type, id, snapshot, _query) {
        var _this2 = this;
        //Logger.log('urlSegments.query: ', ...arguments);

        // dans les autres fonctions urlSegments, on utilise l'objet
        // query pour déterminer les différents segments MAIS on ne doit
        // surtout pas modifier l'objet query (avec un "delete query.prop"
        // par ex) car cela engendre des effets de bords.
        //
        // Effectivement, dans le hook "model()"" d'un controller, on peut
        // utiliser :
        //
        // model: function(params/*, transition*/) {
        //     console.log(params, params.id, typeof params.id, ...arguments);
        //     const model = this.get('store').queryRecord('timesreport', params);
        //     console.log(params, params.id, typeof params.id, ...arguments);
        //     ...
        //
        // `params.id` est bien défini lors du 1er `console.log`
        // MAIS, si on faisait un "delete query.id" dans urlSegments.id(),
        // params.id ne le serait plus dans le 2nd !!!
        //
        // DONC NE PAS MODIFIER query DANS LES METHODS urlSegments.* !!!!!!
        //
        // MAIS, si on ne modifie pas query, ses propriétés vont se retrouver
        // dans la chaine queryParams :/ Il faut donc les filtrer ici !
        //

        var filteredQuery;
        if (_query) {
          filteredQuery = Ember.assign({}, _query);
          Object.keys(filteredQuery).forEach(function (key) {
            if (typeof _this2.urlSegments[key] !== 'undefined') {
              // remove queryparams already in url like 'id'
              delete filteredQuery[key];
            } else if (typeof filteredQuery[key] === 'undefined') {
              // remove undefined queryparams
              delete filteredQuery[key];
            }
          });

          // if "queryParams" key exists, we add all its item in filteredQuery.
          // with "queryParams" object, we can add some item already in url like 'id'
          if (_typeof(filteredQuery.queryParams) === 'object') {
            filteredQuery = Ember.assign(filteredQuery, filteredQuery.queryParams);
            delete filteredQuery.queryParams;
          }
          if (this.sortQueryParams) {
            filteredQuery = this.sortQueryParams(filteredQuery);
          }
        }
        return filteredQuery;
      },
      format: function format(type, id, snapshot, query) {
        if (query && query.format) {
          return query.format;
        }
      },
      middlepoint: function middlepoint(type, id, snapshot, query) {
        if (query && query.middlepoint) {
          return query.middlepoint;
        } else if (typeof id !== 'undefined' && (0, _utils.isDefined)(snapshot, 'adapterOptions.middlepoint')) {
          return snapshot.adapterOptions.middlepoint;
        }
      },
      pathForType: function pathForType(type, id, snapshot, query) {
        if (query && query.pathForType) {
          return query.pathForType;
        } else if (typeof id !== 'undefined' && (0, _utils.isDefined)(snapshot, 'adapterOptions.pathForType')) {
          return snapshot.adapterOptions.pathForType;
        } else {
          return this.pathForType(type);
        }
      },
      endpoint: function endpoint(type, id, snapshot, query) {
        if (query && query.endpoint) {
          return query.endpoint;
        } else if (typeof id !== 'undefined' && (0, _utils.isDefined)(snapshot, 'adapterOptions.endpoint')) {
          return snapshot.adapterOptions.endpoint;
        }
      },
      id: function id(type, _id, snapshot, query) {
        var normalizedID = _id;
        if (query && query.id) {
          normalizedID = query.id;
        }
        return normalizedID === 'default' ? '0' : normalizedID;
      }
    }),
    // force data reload from backend for each request !
    shouldReloadRecord: function shouldReloadRecord( /*store, snapshot*/
    ) {
      return true;
    },
    shouldReloadAll: function shouldReloadAll( /*store, snapshot*/
    ) {
      return true;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord( /*store, snapshot*/
    ) {
      return false;
    },
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll( /*store, snapshot*/
    ) {
      return false;
    },
    // redefine query to make HTTP verb dynamic (retrieve from model class)
    // and to fix double queryParams
    query: function query(store, type, _query2) {
      var _this3 = this;
      var verb = type.queryVerb && type.queryVerb.toUpperCase() || 'GET';
      // recreate query params if get request
      // to avoid problems with coma in ids from admin values
      // in input values
      var newQueryParams = {};
      Object.keys(_query2).forEach(function (keyName) {
        // escape characters if array in query to avoid coma problems
        // see https://wishgroupe.atlassian.net/browse/BM-213 for details
        // GRI : modified that logic to include an array of escaped characters.
        // see https://wishgroupe.atlassian.net/browse/BM-866 for details
        var encodedCharacters = ['+', ','];
        if (Ember.isArray(_query2[keyName])) {
          newQueryParams[keyName] = [];
          _query2[keyName].forEach(function (item, index) {
            var newItem = item;
            if (typeof newItem === 'string') {
              encodedCharacters.forEach(function (character) {
                newItem = newItem.split(character).join(encodeURIComponent(character));
              });
            }
            newQueryParams[keyName][index] = newItem;
          });
        } else {
          newQueryParams[keyName] = _query2[keyName];
        }
      });
      var url = this.buildURL(type.modelName, null, null, 'query', newQueryParams);

      //CAUTION: URLTemplate use query* when use buildUrl() so if we use
      //this.ajax with {data: query}, queryParams are added twice !
      // cf ISSUE https://github.com/amiel/ember-data-url-templates/issues/19
      /*
      if (this.sortQueryParams) {
      	query = this.sortQueryParams(query);
      }
      console.log('====> ', url, verb, query);
      return this.ajax(url, verb, {data: query});
      */

      return this.ajax(url, verb).then(function (response) {
        return response;
      }, function (error) {
        if (error instanceof _versionError.default) {
          return _this3.get('router').transitionTo('badversion');
        }
        throw error;
      });
    },
    // redefine queryRecord to make HTTP verb dynamic (retrieve from model class)
    // and to fix double queryParams
    queryRecord: function queryRecord(store, type, query) {
      var _this4 = this;
      var url = this.buildURL(type.modelName, null, null, 'queryRecord', query);
      var verb = type.queryVerb && type.queryVerb.toUpperCase() || 'GET';

      //CAUTION: URLTemplate use query* when use buildUrl() so if we use
      //this.ajax with {data: query}, queryParams are added twice !
      // cf ISSUE https://github.com/amiel/ember-data-url-templates/issues/19
      /*
      if (this.sortQueryParams) {
      	query = this.sortQueryParams(query);
      }
      console.log('====> ', url, verb, query);
      return this.ajax(url, verb, {data: query});
      */

      return this.ajax(url, verb).then(function (response) {
        return response;
      }, function (error) {
        if (error instanceof _versionError.default) {
          return _this4.get('router').transitionTo('badversion');
        }
        throw error;
      });
    },
    // override methode to use PUT instead of PATCH
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var url,
        fields = Ember.getWithDefault(snapshot, 'adapterOptions.fields', []),
        queryParams = Ember.getWithDefault(snapshot, 'adapterOptions.queryParams', {}),
        modelType = Ember.getWithDefault(snapshot, 'adapterOptions.type', type.modelName);
      if (fields.length) {
        var keys = Object.keys(data.data.attributes);
        for (var i in keys) {
          if (!fields.includes(keys[i])) delete data.data.attributes[keys[i]];
        }
        keys = Object.keys(data.data.relationships);
        for (var _i in keys) {
          if (!fields.includes(keys[_i])) delete data.data.relationships[keys[_i]];
        }
        url = this.buildURL(modelType, snapshot.id, snapshot, 'updateRecord', Ember.assign({
          fields: fields.join(',')
        }, queryParams));
      } else {
        url = this.buildURL(modelType, snapshot.id, snapshot, 'updateRecord', queryParams);
      }
      return this.ajax(url, 'PUT', {
        data: data
      });
    },
    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var queryParams = Ember.getWithDefault(snapshot, 'adapterOptions.queryParams', {});
      var url = this.buildURL(type.modelName, snapshot.id, snapshot, 'createRecord', queryParams);
      return this.ajax(url, 'POST', {
        data: data
      });
    },
    // direct call to the api non-related to a model
    call: function call(endpoint, verb, params, dataType) {
      var url = this._buildURL() + endpoint;
      return this.ajax(url, verb || 'GET', {
        data: params,
        dataType: dataType || 'json'
      });
    },
    export: function _export(model, params) {
      var _this5 = this;
      var url = this._buildURL(model, null, null, 'query');
      return this.ajax(url, 'GET', {
        data: params
      }).then(function () {
        //Le back ne renvoie pas les fichiers exportés.
        var routeName = _this5.get('router.currentRouteName');
        Ember.getOwner(_this5).lookup("route:".concat(routeName)).refresh(true);
      });
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (!this.isSuccess(status, headers, payload)) {
        this.get('notify').set('genericErrorModal.lastError', {
          status: status,
          payload: payload,
          requestData: requestData
        });
        // debug
        if (_environment.default.environment === 'development') {
          _logger.default.warn('JSONAPIAdapter::handleResponse', {
            adapter: this,
            status: status,
            // HTTP status code en version numérique :/
            headers: headers,
            // En-têtes de la réponse du Back
            payload: payload,
            // réponse renvoyée par le Back
            // requestData.url = url de la requête
            // requestData.method = "GET" || "POST" || etc de la requête
            requestData: requestData
          });
        }
      }
      this.get('i18n').detectLanguage(payload);
      this.ensureResponseAuthorized(status, headers, payload, requestData);
      return this.ensureBmVersion(status, headers, payload, requestData) || this._super.apply(this, arguments);
    },
    /**
     The default implementation for handleResponse.
     If the response has a 401 status code it invalidates the session (see
     {{#crossLink "SessionService/invalidate:method"}}{{/crossLink}}).
     Override this method if you want custom invalidation logic for incoming responses.
     @method ensureResponseAuthorized
     @param {Number} status The response status as received from the API
     @param  {Object} headers HTTP headers as received from the API
     @param {Any} payload The response body as received from the API
     @param {Object} requestData the original request information
    */
    ensureResponseAuthorized: function ensureResponseAuthorized(status /* ,headers, payload, requestData */) {
      if (status === 401 && this.get('session.isAuthenticated')) {
        /**
         * FIXME : risque si on a une 401 sur un onglet en cours de refresh et une connexion sur un autre.
         * Pourquoi faut il déclencher un nettoyage en cas de 401 ? =>
         *   scenario 1: l'utilisateur clique sur un lien, mais les sessions ont été nettoyées coté serveur
         *       => en cas de soumission de formulaire (requete PUT / POST) on devrait juste afficher une modale pour que l'utilisateur puisse se reloguer sans perdre son boulot sur un autre onglet
         *       => en cas de navigation on devrait le renvoyer sur une 401 non identifiée
         *  ça a l'air malgré tout recommandé par Ember Simple Auth. Si la session ne correspond plus coté serveur, il faudrait la nettoyer coté navigateur pour que ce soit iso
         *  => peut etre ne faut il pas avoir de deconnexion intermédiaire en cas de switch de compte
         */
        this.get('session').invalidate();
      }
    },
    /**
     If the response has a meta.version not equal to current Front version, we return
     a DS.AdapterError with a 666 code
     @method ensureBmVersion
     @param {Number} status The response status as received from the API
     @param  {Object} headers HTTP headers as received from the API
     @param {Any} payload The response body as received from the API
     @param {Object} requestData the original request information
     @throws VersionError
    */
    ensureBmVersion: function ensureBmVersion(status, headers, payload, requestData) {
      // test version
      //On ignore le bad version si succès de la suppression. Les GET suivants vont déclencher la redirection vers la page d'erreur.
      var ignoreBadVersion = requestData['method'] === 'DELETE' && status === 200;
      var checkVersion = _environment.default.checkVersion && !ignoreBadVersion || true;
      if (!checkVersion) {
        return;
      }

      /*
       * il y'a 2 modes distincts, un mode automatique et un mode piloté par le back:
       *  en mode "automatique" :
       *   - si une version majeur est differente, on leve une exception,
       *   - si c'est une version mineure, on met juste un warning,
       *   - si c'est du debug (hotfixes ou autres) on ne fait rien
       *  en mode "manuel" : le back envoie un min version, si ce min est supérieur au front, on leve une exception
       */
      var minVersion = Ember.get(payload, 'meta.frontVersionRequired');
      var backVersion = Ember.get(payload, 'meta.version');
      var localVersion = _environment.default.version;

      // si on a un format autre que X.Y.Z, on fait une comparaison des version sous forme de texte
      if (typeof backVersion !== 'undefined') {
        if (!backVersion.match(/^v?([0-9]+)\.([0-9]+)\.([0-9]+)/)) {
          if (localVersion !== backVersion) {
            /* eslint-disable no-console */
            console.warn('Back & Front versions mismatch', 'back end version:', backVersion, 'front end version:', localVersion);
            /* eslint-enable no-console */
            this.notifyNewVersion();
          }
        } else {
          // si à une version inférieur au min version, cela n'est pas cohérent
          // mais c'est une configuration qui permet d'identifier un environnement de dev
          // (versions tagués a partir de la date)
          var isProductionBuild = this.compareBoondVersion(backVersion, minVersion) >= 0;

          // si un minVersion existe en production, on va forcer une comparaison de version et lever la page d'erreur si besoin.
          var compareWithMinVersion = minVersion && isProductionBuild && this.compareBoondVersion(localVersion, minVersion);
          if (backVersion && this.compareMajorVersion(backVersion, localVersion) > 0 || compareWithMinVersion < 0) {
            throw new _versionError.default();
          } else if (backVersion && this.compareMinorVersion(backVersion, localVersion) > 0) {
            /* eslint-disable no-console */
            console.warn('Back & Front versions mismatch', 'back end version:', backVersion, 'front end version:', localVersion);
            /* eslint-enable no-console */
            this.notifyNewVersion();
          }
        }
      }
    },
    /**
     * send a warning for a new version
     */
    notifyNewVersion: function notifyNewVersion() {
      var i18n = this.get('i18n');
      this.get('notify').warning(i18n.t('common:modals.reload.message'), i18n.t('common:modals.reload.title'), {
        preventDuplicates: true,
        timeOut: 0,
        extendedTimeOut: 0,
        onclick: function onclick() {
          return window.location.reload();
        }
      });
    },
    /**
     * compare the major version ( comparaison is based on `X.Y` for a version like `vX.Y.Z`)
     * @param a
     * @param b
     * @return {number} 1 if a > b, 0 if a = b, -1 if a < b
     */
    compareMajorVersion: function compareMajorVersion(a, b) {
      var regex = /^v?([0-9]+\.[0-9]+).*$/;
      var majorA = regex.exec(a)[1];
      var majorB = regex.exec(b)[1];
      return this.compareBoondVersion(majorA, majorB);
    },
    /**
     * compare the major version ( comparaison is based on `Z` for a version like `vX.Y.Z`)
     * @param a
     * @param b
     * @return {number} 1 if a > b, 0 if a = b, -1 if a < b
     */
    compareMinorVersion: function compareMinorVersion(a, b) {
      var regex = /^v?[0-9]+\.[0-9]+\.([0-9]+).*$/;
      var minorA = regex.exec(a)[1];
      var minorB = regex.exec(b)[1];
      if (minorA > minorB) return 1;
      if (minorA < minorB) return -1;
      return 0;
    },
    /**
     * Compare 2 version, if there is other characters than [0-9] there will be an alphabetic comparaison
     * @param a
     * @param b
     * @return {number} 1 if a > b, 0 if a = b, -1 if a < b
     */
    compareBoondVersion: function compareBoondVersion(a, b) {
      //treat non-numerical characters as lower version
      //replacing them with a negative number based on charcode of each character
      function fix(s) {
        return '.' + (s.toLowerCase().charCodeAt(0) - 2147483647) + '.';
      }
      a = ('' + a).replace(/[^0-9.]/g, fix).split('.');
      b = ('' + b).replace(/[^0-9.]/g, fix).split('.');
      var c = Math.max(a.length, b.length);
      for (var i = 0; i < c; i++) {
        //convert to integer the most efficient way
        a[i] = ~~a[i];
        b[i] = ~~b[i];
        if (a[i] > b[i]) return 1;else if (a[i] < b[i]) return -1;
      }
      return 0;
    }
  });
});