define("boondmanager/pods/components/octane/bm-stepper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "NFL9Mhw0",
    "block": "{\"symbols\":[\"step\",\"index\",\"@steps\"],\"statements\":[[10,\"div\"],[14,0,\"bmc-stepper\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[15,0,[31,[\"bmc-stepper_step\",[30,[36,3],[[30,[36,4],[[32,0,[\"currentStep\"]],[32,2]],null],\" active\"],null],[30,[36,3],[[30,[36,2],[[32,0,[\"currentStep\"]],[32,2]],null],\" done\"],null]]]],[12],[2,\"\\n\\t\\t\\t\"],[10,\"span\"],[14,0,\"bmc-stepper_step-content\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,0,[\"currentStep\"]],[32,2]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[30,[36,1],[\"bmi-check\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\\t\"],[1,[30,[36,0],[[32,0,[\"increment\"]],[32,2]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"span\"],[14,0,\"bmc-stepper_step-label\"],[12],[1,[32,1]],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13]],\"hasEval\":false,\"upvars\":[\"compute\",\"icon\",\"gt\",\"if\",\"eq\",\"-track-array\",\"each\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-stepper/template.hbs"
  });
});