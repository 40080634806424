define("boondmanager/pods/components/octane/bm-fieldset/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "dXKYhnnC",
    "block": "{\"symbols\":[\"&default\",\"&attrs\",\"@namedBlocksInfo\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-fieldset\"],[17,2],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,3],\"title\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"h3\"],[14,0,\"bmc-fieldset_title\"],[12],[2,\"\\n\\t\\t\\t\"],[18,1,[[30,[36,0],[\"title\"],null]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[30,[36,1],[[32,3],\"content\",false],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\"],[10,\"div\"],[14,0,\"bmc-fieldset_content\"],[12],[2,\"\\n\\t\\t\\t\"],[18,1,[[30,[36,0],[\"content\"],null]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\",\"-has-block\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-fieldset/template.hbs"
  });
});